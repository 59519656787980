import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  public search: any = null;
  public users: any = null;
  public next_page: string = null;
  public previus_page: string = null;
  public count: number = 0;
  public username: string = null;
  public email: string = null;
  public password1: string = null;
  public password2: string = null;

  constructor(
    private apiService: ApiServices,
    private toast: ToastrService,
    public modal: NgbModal,
  ) { }

  async ngOnInit() {
    this.getUSers();
  }


  getUSers(){
    this.apiService.getAllUser(this.search).subscribe((data: any) => {
      this.users = data.results;
      this.count = data.count;
      this.next_page = data.next;
      this.previus_page = data.previous;
    }, () => {
      this.toast.error('Unable to getting user list', 'Upsss...', { positionClass: 'toast-top-center' });
    });
  }

  public changeList(url: string) {
    this.apiService.getAllUser(url).subscribe((data: any) => {
      this.users = data.results;
      this.count = data.count;
      this.next_page = data.next;
      this.previus_page = data.previous;
    }, (error) => {
      this.toast.error('Unable to getting user list', 'Upsss...', { positionClass: 'toast-top-center' });
    });
  }

  public changeStatusUser(usr_id: string) {
    this.apiService.toogleUserStatus(usr_id).subscribe((response) => {

    }, (error) => {
      this.toast.error('Unable to change User Status. Try again later', 'Upsss...', { positionClass: 'toast-top-center' });
    })
  }

  public changeStaffUser(usr_id: string) {
    this.apiService.toogleUserAdmin(usr_id).subscribe((response) => {

    }, (error) => {
      this.toast.error('Unable to change User Staff status. Try again later', 'Upsss...', { positionClass: 'toast-top-center' });
    })
  }

  public changeRoleUser(usr_id, role) {
    this.apiService.changeUserRole(usr_id, role).subscribe((response) => {
      this.toast.success('User Role Changed successfully', 'Success', { positionClass: 'toast-top-center' });
    }, (er) => {
      this.toast.error('Unable to change User Role. Try again later', 'Upsss...', { positionClass: 'toast-top-center' });
    });
  }


  public createUser() {


    if (
      this.password1 == null &&
      this.password2 == null &&
      this.username == null &&
      this.email == null
    ) {
      this.toast.warning('full fill all inputs', 'unfill input', { positionClass: 'toast-top-center' });
      return;
    }
    if (this.password1 != this.password2) {
      this.toast.warning('password not match place check out and try again', 'not mach password', { positionClass: 'toast-top-center' });
      return;
    }

    this.apiService.CreateUserRole(
      this.username, this.password1, this.email)
      .subscribe((response) => {
        this.modal.dismissAll();
        this.ngOnInit();
        this.toast.success('User ' + this.username + ' Created', 'Success', { positionClass: 'toast-top-center' });
      },
        (error) => {
          this.toast.error('Unable to create the user, pleace Try again later', 'Ups...', { positionClass: 'toast-top-center' });
        });
  }

}
