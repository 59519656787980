import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiServices } from '../../services/services'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  @ViewChild('username') Username: ElementRef;
  @ViewChild('password') password: ElementRef;

  public loading: boolean = false

  constructor(private api: ApiServices, private router: Router,private toastr: ToastrService ) { }

 async ngOnInit() {
    const username = await localStorage.setItem('username', this.Username.nativeElement.value)
    const password =  await localStorage.setItem('password', this.password.nativeElement.value)
    if(username != null && password != null) {
      
      this.Username.nativeElement.value = username;
      this.password.nativeElement.value = password;
    }
  }


  private async getUserRole() {
    this.api.getUSerRole().subscribe((response: any)=>{
      localStorage.setItem('role', response.role );
    },(error) => {this.toastr.error('','error Getting User Role', { positionClass: 'toast-top-center' });});
  }


  async login() {
    this.loading = true;
    this.api.AuthtenticationUser(this.Username.nativeElement.value, this.password.nativeElement.value)
    .subscribe((response) => {
      localStorage.setItem('token', 'token ' + response.token);
      this.getUserRole();
      this.router.navigate(['dashboard']);
      this.loading = false;
    }, (error)=>{
      this.toastr.error('Unable to login pleace checkout Username o Password:','Login fail', { positionClass: 'toast-top-center' });
      this.loading = false;
    });
  }

  save() {
    localStorage.setItem('username', this.Username.nativeElement.value)
    localStorage.setItem('password', this.password.nativeElement.value)
  }

}
