import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';
import Chart from 'chart.js';

@Component({
  selector: 'app-acc-detail',
  templateUrl: './acc-detail.component.html',
  styleUrls: ['./acc-detail.component.scss']
})
export class AccDetailComponent implements OnInit {

  private id:string = '';
  public obj: any;
  public loading:boolean = false

  public canvas : any;
  public ctx;
  public datasets: any;
  public data: any;
  public myChartData;
  public clicked: boolean = true;

  public detail_name = null;
  public detail_number = null;
  public detail_wallet = null;
  public detail_type = null;
  public detail_status = null;
  public detail_client = null;
  public detail_symbol = null;
  public detail_pk = null;
  public swap_report = null;

  public inValue = '$0';
  public outValue = '$0';
  clients: any;

  constructor(
    private apiservice: ApiServices,
    private modal: NgbModal,
    private toast: ToastrService,
    private _Activatedroute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.id = this._Activatedroute.snapshot.paramMap.get("id");
    this.getAccountDetail();
    this.getClientsAvailables();
  }

  getAccountDetail() {
    this.apiservice.AccountsDetail(this.id).subscribe((response)=>{
      this.obj=response;
      this.loadData();
    },(error) => {
      this.toast.error('Unable to get accounts', 'Ups...', { positionClass: 'toast-top-center' });
    },()=>{this.loading=false})
  }

  getClientsAvailables() {
    this.apiservice.ClientAllAvailables().subscribe((data) => {
      this.clients = data.results;
    })
  }

  accountUpdate() {
    this.apiservice.AccountsUpdate(
      this.detail_name,
      this.detail_number,
      this.detail_wallet,
      this.detail_type,
      this.detail_status,
      this.detail_client,
      this.detail_symbol,
      this.detail_pk,
      this.swap_report
    ).subscribe((response)=> {
      this.toast.success('Client "' + this.detail_name + '" Updated', 'Success', { positionClass: 'toast-top-center' });

      this.ngOnInit();
    },(error) => {
      this.toast.error('Unable to update the Account, pleace Try again later', 'Ups...', { positionClass: 'toast-top-center' });
    });
    this.modal.dismissAll();
  }

  loadData() {
    this.detail_name = this.obj.name;
    this.detail_number = this.obj.number;
    this.detail_wallet = this.obj.wallet;
    this.detail_type = this.obj.type;
    this.detail_status = this.obj.status;
    this.detail_client = this.obj.client;
    this.detail_symbol = this.obj.symbol;
    this.detail_pk = this.obj.id
    this.outValue = this.obj.total_out;
    this.inValue = this.obj.total_in;
    this.configureTable();
  }

  configureTable() {

    var gradientBarChartConfiguration: any = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },

      tooltips: {
        backgroundColor: '#f5f5f5',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest"
      },
      responsive: true,
      scales: {
        yAxes: [{

          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 120,
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }],

        xAxes: [{

          gridLines: {
            drawBorder: false,
            color: 'rgba(29,140,248,0.1)',
            zeroLineColor: "transparent",
          },
          ticks: {
            padding: 20,
            fontColor: "#9e9e9e"
          }
        }]
      }
    };

    this.canvas = document.getElementById("CountryChart");
    this.ctx  = this.canvas.getContext("2d");

    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
    gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
    gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

    let labels = [];
    let values = [];

    this.obj.total_in_month.forEach(element => {
      labels.push(element.date)
      values.push(element.amount_final__sum)
    });

    var myChart = new Chart(this.ctx, {
      type: 'bar',
      responsive: true,
      legend: {
        display: false
      },
      data: {
        labels: labels,
        datasets: [{
          label: this.detail_symbol,
          fill: true,
          backgroundColor: gradientStroke,
          hoverBackgroundColor: gradientStroke,
          borderColor: '#1f8ef1',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: values,
        }]
      },
      options: gradientBarChartConfiguration
    });

    this.canvas = document.getElementById("outValue");
    this.ctx  = this.canvas.getContext("2d");

    var gradientStroke = this.ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
    gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
    gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); //blue colors

    let labelsout = [];
    let valuesout = [];

    this.obj.total_out_month.forEach(element => {
      labelsout.push(element.date)
      valuesout .push(element.amount_initial__sum)
    });

    var myChart = new Chart(this.ctx, {
      type: 'bar',
      responsive: true,
      legend: {
        display: false
      },
      data: {
        labels: labelsout,
        datasets: [{
          label: this.detail_symbol,
          fill: true,
          backgroundColor: gradientStroke,
          hoverBackgroundColor: gradientStroke,
          borderColor: '#1f8ef1',
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          data: valuesout,
        }]
      },
      options: gradientBarChartConfiguration
    });


  }



}
