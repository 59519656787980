import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tran-record',
  templateUrl: './tran-record.component.html',
  styleUrls: ['./tran-record.component.scss']
})
export class TranRecordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
