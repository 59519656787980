import { Component, OnInit } from "@angular/core";

declare interface RouteInfo {
  path: string;
  title: string;
  rtlTitle: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard/home",
    title: "Dashboard",
    rtlTitle: "",
    icon: "icon-chart-pie-36",
    class: ""
  },

  {
    path: "user",
    title: "User Manager",
    rtlTitle: "",
    icon: "icon-single-02",
    class: ""
  },
  {
    path: "clients",
    title: "Clients",
    rtlTitle:"",
    icon: "icon-credit-card",
    class: ""
  },
  {
    path: "accounts",
  title: "Account",
    rtlTitle:"",
    icon: "icon-book-bookmark",
    class: ""
  },
  {
    path: "transaction",
  title: "Transaction",
    rtlTitle:"",
    icon: "icon-book-bookmark",
    class: ""
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() {}

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
