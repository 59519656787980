import { Component, OnInit } from '@angular/core';
import { ApiServices } from 'src/services/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-type-balance',
  templateUrl: './type-balance.component.html',
  styleUrls: ['./type-balance.component.scss']
})
export class TypeBalanceComponent implements OnInit {


  public obj_list: object = null;

  constructor(  private apiservice: ApiServices,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.apiservice.getTransactionsTypeBalance().subscribe((response)=>{
      this.obj_list = response;
    }, (error)=>{
      this.toast.error('Cant obtain acc type TxT balance', 'Uopsss...')
      console.error(error)
    });
  }


}
