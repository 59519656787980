import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-icons",
  templateUrl: "icons.component.html"
})
export class IconsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
