import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from "src/services/services";

@Component({
  selector: "app-user",
  templateUrl: "user.component.html"
})
export class UserComponent implements OnInit {

  public object: any = ''
  public id = null;
  public role = null;
  @ViewChild('Username',{static: true}) Username: ElementRef;
  // @ViewChild('Username') Username: ElementRef;
  @ViewChild('firstname') firstname: ElementRef;
  @ViewChild('lastname') lastname: ElementRef;
  @ViewChild('email') email: ElementRef;


  constructor(
    private _Activatedroute: ActivatedRoute,
    private toastr: ToastrService,
    private api_service: ApiServices,
    private router: Router
  ) { }


  update() {
    this.api_service.UpdateUserProfile(
      this.Username.nativeElement.value,
      this.firstname.nativeElement.value,
      this.lastname.nativeElement.value,
      this.email.nativeElement.value,
      this.id
    ).subscribe((data) => {
      this.toastr.success('User data Updated successfully', 'User Updated:', { positionClass: 'toast-top-center' });
      this.router.navigate(['dashboard/user']);
    }, (error) => {
      this.toastr.error('User data Updated failed', 'error:User Updated:', { positionClass: 'toast-top-center' })
    });
  }

  ngOnInit() {
    let id = this._Activatedroute.snapshot.paramMap.get("id");
    this.role = localStorage.getItem('role');
    this.id = id != null ? id : null;
    this.getDataUser();
  }

  private getDataUser() {
    this.api_service.getUserProfile(this.id).subscribe((data) => {
      this.object = data
      console.log(data);
    })
  }

  public changeStatusUser() {
    this.api_service.toogleUserStatus(this.object.id).subscribe((response) => {
      this.toastr.success('User Status Changed successfully', 'Success', { positionClass: 'toast-top-center' });

    }, (error) => {
      this.toastr.error('Unable to change User Status. Try again later', 'Upsss...', { positionClass: 'toast-top-center' });
    })
  }

  public changeStaffUser() {
    this.api_service.toogleUserAdmin(this.object.id).subscribe((response) => {

      this.toastr.success('User Staff Status Changed successfully', 'Success', { positionClass: 'toast-top-center' });
    }, (error) => {
      this.toastr.error('Unable to change User Staff status. Try again later', 'Upsss...', { positionClass: 'toast-top-center' });
    })
  }

  public changeRoleUser(usr_id, role) {
    this.api_service.changeUserRole(usr_id, role).subscribe((response) => {
      this.toastr.success('User Role Changed successfully', 'Success', { positionClass: 'toast-top-center' });
    }, (er) => {
      this.toastr.error('Unable to change User Role. Try again later', 'Upsss...', { positionClass: 'toast-top-center' });
    });
  }

}
