import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  constructor(private apiService: ApiServices,
    private toas: ToastrService,
    private modal: NgbModal) { }

  @ViewChild('detailModal') detailModal: any;
  @ViewChild('deleteClient') deleteClientModal: any;
  public obj: any = null
  public seach: string = null
  private first_name: string = null;
  private last_name: string = null;
  private doc_number: string = null;
  private email: string = null;
  private interal: boolean = true;
  private detail_pk: string = null;
  private detail_first_name: string = null;
  private detail_last_name: string = null;
  private detail_doc_number: string = null;
  private detail_email: string = null;
  private detail_interal: string = null;
  public next_page: string = null;
  public previus_page: string = null;
  public count: number = 0;

  ngOnInit(): void {
    this.getCLientsList();
  }

  getCLientsList() {
    if (this.seach != null && this.seach != '') {
      console.log('que?');
      this.apiService.ClientAllAvailablesSearch(this.seach).subscribe((data) => {
        this.obj = data.results;
        this.count = data.count;
      }, (error) => {
        console.error(error);
        this.toas.error('Unable to get Clients List', 'Upsss...');
      });
    } else {
      if (localStorage.getItem('role') == 'ad') {
        this.apiService.ClientAll().subscribe((data) => {
          this.obj = data.results;
          this.count = data.count;
          this.next_page = data.next;
          this.previus_page = data.previous;
        }, (error) => {
          console.error(error);
          this.toas.error('Unable to get Clients List', 'Upsss...');
        });
      } else {
        this.apiService.ClientAllAvailables().subscribe((data) => {
          this.obj = data.results;
          this.count = data.count;
          this.next_page = data.next;
          this.previus_page = data.previous;
        }, (error) => {
          console.error(error);
          this.toas.error('Unable to get Clients List', 'Upsss...');
        });
      }
    }
  }

  changeList(url: string) {

  }

  createClient() {
    if (
      this.first_name == null &&
      this.last_name == null &&
      this.email == null &&
      this.doc_number == null
    ) {
      this.toas.warning('full fill all inputs', 'unfill input', { positionClass: 'toast-top-center' });
      return;
    }
    this.apiService.CreateClient(this.first_name, this.last_name, this.doc_number, this.email).subscribe((data) => {
      this.toas.success('Client ' + this.first_name + ' Created', 'Success', { positionClass: 'toast-top-center' });
      this.modal.dismissAll();
      this.ngOnInit();
    }, (error) => {
      this.toas.error('Unable to create the client, pleace Try again later', 'Ups...', { positionClass: 'toast-top-center' });
    })
  }

  updateClient() {
    this.apiService.clientUpdate(
      this.detail_first_name,
      this.detail_last_name,
      this.detail_doc_number,
      this.detail_email, this.detail_pk
    ).subscribe((response) => {
      this.toas.success('Client "' + this.detail_first_name + ' ' + this.detail_last_name + '" Updated', 'Success', { positionClass: 'toast-top-center' });
      this.modal.dismissAll();
      this.ngOnInit();
    }, (error) => {
      this.toas.error('Unable to update the Client, pleace Try again later', 'Ups...', { positionClass: 'toast-top-center' });
    })

  }

  ChangeClientStatus(pk) {
    this.apiService.clientDelete(pk)
      .subscribe((response) => {
        // this.toas.success('Client changed', 'Success', { positionClass: 'toast-top-center' });
        this.ngOnInit();
      }, (error) => {
        this.toas.error('Unable to change status Client, pleace Try again later', 'Ups...', { positionClass: 'toast-top-center' });
      })
  }

  public opendetailmodal(obj) {
    this.detail_first_name = obj.first_name;
    this.detail_last_name = obj.last_name;
    this.detail_doc_number = obj.doc_number;
    this.detail_email = obj.email;
    this.detail_pk = obj.id;
    this.detail_interal = obj.internal;
    this.modal.open(this.detailModal);
  }



}
