import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit {

  private role = null;

  // template modals
  @ViewChild('detailModal') detail_modal: ElementRef;
  @ViewChild('createModal') create_modal: ElementRef;

  // Template list object
  private obj_list: any;
  private obj_list_clients: any;
  private obj_list_typess: any;
  public obj_list_acconts: any;
  public next_page: string = null;
  public previus_page: string = null;
  public count: number = 0;
  public search: string = null;
  public dti: any = null;
  public dtf: any = null;
  public type_filter: string = null;


  //template object create/update/detail
  public acc_from: string;
  public acc_target: string;
  public amount_initial: number = 0;
  public operator: string;
  public amount_final: number = 0;
  public note: string;
  public id: string;
  public allowSubTrax: boolean;
  public status: string;
  public money_type: any;
  public sub_trans: any;
  public sub_id: string;
  public type: string;
  public client: string;

  public loading: boolean = false

  constructor(private apiservice: ApiServices,
    private toast: ToastrService,
    private modal: NgbModal,
    private router: Router) { }

  ngOnInit(): void {
    this.role = localStorage.getItem('role');
    this.getTransacctions();
    this.getAccounts();
    this.clearValues();
    this.getTypesTransactions();
    this.getClientsAvailables();
  }

  clearValues() {
    this.acc_from = '';
    this.acc_target = '';
    this.amount_initial = 0;
    this.operator = '';
    this.amount_final = 0;
    this.note = '';
    this.id = '';
    this.allowSubTrax = false;
    this.sub_id = '';
  }

  getTransacctions(url: string = null) {
    if (this.role == 'ad') {
      this.apiservice.getTransactions(url).subscribe((data) => {
        console.log(data);
        this.setDataToObject(data)
      }, (error) => { this.onErrorTransSacction(error) })

    } else {
      this.apiservice.getTransactionsAvailable(url).subscribe((data) => {
        this.setDataToObject(data)
      }, (error) => { this.onErrorTransSacction(error) })
    }
  }

  getTransacctionsFilter(search: string = null) {
    if (search == "") {
      return this.getTransacctions()
    }
    this.apiservice.getTransactionsSearch(search).subscribe((data) => {
      this.setDataToObject(data)
    }, (error) => { this.onErrorTransSacction(error) })
  }

  getTransacctionsFilterByDate() {
    if (this.dti != null && this.dtf) {
      this.apiservice.getTransactionsFilterDate(this.dti, this.dtf).subscribe((data) => {
        this.setDataToObject(data)
      }, (error) => { this.onErrorTransSacction(error) })
    }
  }

  getTransacctionsFilterbyType() {
    if (this.type_filter == undefined) {
      this.getTransacctions();
    }
    else {
      this.apiservice.getTransactionsFilterType(this.type_filter).subscribe((data) => {
        this.setDataToObject(data)
      }, (error) => { this.onErrorTransSacction(error) })
    }
  }

  onErrorTransSacction(error) {
    this.toast.error('Error getting transsactions, please try again', 'OUCH......');
    console.error(error);
  }

  setDataToObject(data) {
    this.obj_list = data.results;
    this.next_page = data.next;
    this.previus_page = data.previous;
    this.count = data.count;
  }

  createTrassaction() {
    if (this.acc_from && this.acc_target && this.amount_initial) {
      this.loading = true;
      this.apiservice.CreateTransactions(this.acc_from, this.acc_target, this.amount_initial.toString(),
        this.operator, this.amount_final.toString(), this.note, this.allowSubTrax, this.money_type, '', this.type, this.client)
        .subscribe((response) => {
          this.toast.success('transaction created', '=)');
          this.modal.dismissAll();
          this.ngOnInit();
        }, (error) => {
          this.toast.error('A error ocurred creating transaction', 'Aff...');
          this.loading = false;
          console.error(error);
        }, () => { this.loading = false; })
    }
  }

  createSubTrassaction() {
    this.modal.dismissAll();
    this.router.navigate(['/dashboard/transaction/detail/', this.id]);
  }

  public openDetailModal(id: string) {
    this.clearValues();
    this.DetailTrans(id);
  }

  DetailTrans(id) {
    this.apiservice.getTransactionsDetail(id)
      .subscribe((response) => {
        if (response != null) {
          this.acc_from = response.account_from.name;
          this.acc_target = response.account_to.name;
          this.amount_initial = response.amount_initial;
          this.operator = response.operator;
          this.amount_final = response.amount_final;
          this.note = response.note;
          this.id = response.id;
          this.status = response.status;
          this.allowSubTrax = response.allowSubTrans;
          this.money_type = response.money_type;
          this.sub_trans = response.nested;
          this.sub_id = response.id
          this.type = response.type != null ? response.type_txt.name : 'None';
          this.client = response.client != null ? response.client_txt.first_name + " " + response.client_txt.last_name : 'None';
          this.modal.open(this.detail_modal, { size: 'lg' });
        }
      }, (error) => {
        this.toast.error('A error ocurred getting transaction detail', 'Aff...');
        console.error(error);
      })
  }

  public dismiss() {
    this.clearValues();
    this.modal.dismissAll();
  }

  public openCreationModal() {
    this.clearValues();
    this.modal.open(this.create_modal, { size: "lg" })
  }

  private getAccounts() {
    this.apiservice.AccountsAvailables().subscribe((data) => {
      this.obj_list_acconts = data.results
    }, (error) => {
      this.toast.error('A error ocurred getting Accounts Avalaibles', 'ouch...');
      setTimeout(() => {
        this.getAccounts();
      }, 10000);
    })
  }

  private getTypesTransactions() {
    this.apiservice.getTransactionsTypes().subscribe((result) => {
      this.obj_list_typess = result.results
    }, (error) => {
      this.toast.error('A error ocurred getting types', 'ouch...');
      setTimeout(() => {
        this.getTypesTransactions();
      }, 10000);
    });
  }

  getClientsAvailables() {
    this.apiservice.ClientAllAvailables().subscribe((data) => {
      this.obj_list_clients = data.results;
    })
  }

  changeStatusTransaction(txt) {
    let status = '';
    console.log(txt.status);
    switch (txt.status) {
      case 'PxP':
        status = 'C';
        break;
      case 'C':
        status = 'O';
        break;
        case 'C':
        status = 'P';
        break;
        case 'P':
        status = 'PxC';
        break;
        case 'PxC':
        status = 'PxP';
        break;
      default:
        status = 'P';
        break;
    }
    this.apiservice.postTransactionChange(txt.id, status).subscribe(() => {
      // this.toast.success('transaction ' + txt.id + ' changed status to:' + status, '=)');
    }, (error) => {})
    txt.status = status;
  }



}
