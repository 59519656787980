import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./login/login.component";
import { UserComponent } from './pages/user/user.component'
import { UsersListComponent } from "./users-list/users-list.component";
import { IconsComponent } from "./pages/icons/icons.component";
import { ClientsComponent } from "./clients/clients.component";
import { TablesComponent } from "./pages/tables/tables.component";
import { AccountsComponent } from "./accounts/accounts.component";
import { TransactionComponent } from "./transaction/transaction.component";
import { TransactionDetailComponent } from "./transaction-detail/transaction-detail.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AccDetailComponent } from "./acc-detail/acc-detail.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "dashboard",
    component: AdminLayoutComponent,
    children: [
      {
        path: "home",
        component:DashboardComponent
      },
      {
        path: "profile",
        component:UserComponent
      },
      {
        path: "profile/:id",
        component:UserComponent
      },
      {
        path: "user",
        component:UsersListComponent
      },
      {
        path: "clients",
        component:ClientsComponent
      },
      {
        path: "accounts",
        component:AccountsComponent
      },
      {
        path: "accounts/detail/:id",
        component:AccDetailComponent
      },
      {
        path: "transaction",
        component:TransactionComponent
      },
      {
        path: "transaction/detail/:id",
        component:TransactionDetailComponent
      },
      {
        path: "icons",
        component:IconsComponent
      },
      {
        path: "tables",
        component:TablesComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {useHash:true})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
