import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit {

  private role = null;


  constructor(
    private apiservice: ApiServices,
    private toast: ToastrService,
    private router: Router) { }


      // Template list object
  private obj_list: any;
  private obj_list_clients: any;
  public obj_list_acconts: any;
  public next_page: string = null;
  public previus_page: string = null;
  public count: number = 0;

  ngOnInit(): void {
    this.getTransacctions();
  }

  getTransacctions() {
    if (this.role == 'ad') {
      this.apiservice.getTransactions().subscribe((data) => {
        this.setDataToObject(data)
      }, (error) => { this.onErrorTransSacction(error) })

    } else {
      this.apiservice.getTransactionsAvailable().subscribe((data) => {
        this.setDataToObject(data)
      }, (error) => { this.onErrorTransSacction(error) })
    }
  }

  setDataToObject(data) {
    console.log(data);
    this.obj_list = data.results;
    this.next_page = data.next;
    this.previus_page = data.previous;
    this.count = data.count;
  }


  createSubTrassaction(id) {
    this.router.navigate(['/dashboard/transaction/detail/', id]);
  }

  onErrorTransSacction(error) {
    this.toast.error('Error getting transsactions, please try again', 'OUCH......');
    console.error(error);
  }

}
