import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { LoginComponent } from './login/login.component';
import { UserComponent } from "./pages/user/user.component";
import { UsersListComponent } from './users-list/users-list.component';
import { TokenInterceptor } from './http-interceptor';
import { ClientsComponent } from './clients/clients.component';
import { AccountsComponent } from './accounts/accounts.component';
import { TransactionComponent } from './transaction/transaction.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionDetailComponent } from './transaction-detail/transaction-detail.component'
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AccBalanceComponent } from './acc-balance/acc-balance.component';
import { TranRecordComponent } from './tran-record/tran-record.component';
import { AccDetailComponent } from './acc-detail/acc-detail.component';
import { TypeBalanceComponent } from './type-balance/type-balance.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot()
  ],
  declarations: [
    DashboardComponent,
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    UserComponent,
    UsersListComponent,
    ClientsComponent,
    AccountsComponent,
    TransactionComponent,
    TransactionListComponent,
    TransactionDetailComponent,
    AccBalanceComponent,
    TranRecordComponent,
    AccDetailComponent,
    TypeBalanceComponent
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },],
  bootstrap: [AppComponent]
})
export class AppModule { }
