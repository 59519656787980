import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!req.url.includes('api-token-auth')) {
            const userToken = localStorage.getItem('token');
            const modifiedReq = req.clone({
                headers: req.headers.set('Authorization', userToken),
            });
            return next.handle(modifiedReq);
        } else {
            return next.handle(req);
        }
    }
}