import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {

  public obj_list_acconts: any;
  public obj_list_types: object;
  public object: object;
  //template object create/update/detail
  public acc_from: any;
  public acc_target: any;
  public amount_initial: string = "0";
  public operator: string;
  public amount_final: string;
  public note: string;
  public id: string;
  public allowSubTrax: boolean;
  public status: string;
  public money_type: any;
  public sub_trans: any;
  public sub_id: string;
  public allow: number;
  public type: string;

  public acc_target_sub: any;
  public amount_initial_sub: string = "0";
  public operator_sub: string;
  public amount_final_sub: string;
  public money_type_sub: any = null;
  public type_sub: any;
  public note_detail: string;


  constructor(private _Activatedroute: ActivatedRoute, private apiservice: ApiServices,
    private toast: ToastrService,) { }

  async ngOnInit() {
    this.id = await this._Activatedroute.snapshot.paramMap.get("id");
    this.DetailTrans(this.id);
    this.getAccounts();
    this.getTypesTransactions();
  }


  DetailTrans(id) {
    return this.apiservice.getTransactionsDetail(id)
      .subscribe((response) => {
        if (response != null) {
          this.object = response;
          console.log(this.object);
          this.acc_from = response.account_from;
          this.acc_target = "many";
          this.amount_initial = response.amount_initial;
          this.operator = response.operator;
          this.amount_final = response.amount_final;
          this.note = response.note;
          this.id = response.id;
          this.status = response.status;
          this.allowSubTrax = response.allowSubTrans;
          this.money_type = response.money_type;
          this.sub_trans = response.nested;
          this.sub_id = response.id;
          this.type = response.type != null ? response.type_txt.name : 'None';
          return
        }
      }, (error) => {
        this.toast.error('A error ocurred getting transaction detail', 'Aff...');
        console.error(error);
      })
  }

  createSubTrassaction() {
    if (this.acc_target_sub != undefined && this.amount_final_sub != undefined && this.amount_initial_sub != undefined, this.type_sub) {
      this.apiservice.CreateTransactions(this.acc_from.id, this.acc_target_sub, this.amount_initial_sub,
        this.operator_sub, this.amount_final_sub, this.note_detail, false, this.money_type_sub, this.id, this.type_sub)
        .subscribe((response) => {
          this.toast.success('Sub transaction created', '=)');
          this.ngOnInit();
          this.clear();
        }, (error) => {
          this.toast.error('A error ocurred creating transaction', 'Aff...');
          console.error(error);
        })
    }
    else {
      this.toast.error('porfavor indique la cuenta y el monto de la transaccion', 'Aff...');
    }
  }
  clear() {
    this.acc_target_sub = null;
    this.amount_initial_sub = null;
    this.operator_sub = null;
    this.amount_final_sub = null;
    this.money_type_sub = null;
    this.type_sub = null;
    this.note_detail = null;
  }

  private getAccounts() {
    this.apiservice.AccountsAvailables().subscribe((data) => {
      let reply = data.results
      data.results.forEach((acc, index) => {
        if (acc.id == this.acc_from.id) {
          reply.splice(index, 1)
        }
      });

      this.obj_list_acconts = reply;
    }, (error) => {
      this.toast.error('A error ocurred getting Accounts Avalaibles', 'ouch...');
      setTimeout(() => {
        this.getAccounts();
      }, 10000);
    })
  }


  private getTypesTransactions() {
    this.apiservice.getTransactionsTypes().subscribe((result) => {
      this.obj_list_types = result.results
    }, (error) => {
      this.toast.error('A error ocurred getting types', 'ouch...');
      setTimeout(() => {
        this.getTypesTransactions();
      }, 10000);
    });
  }

  changeStatusTransaction(txt) {
    let status = '';
    switch (txt.status) {
      case 'PxP':
        status = 'C';
        break;
      case 'C':
        status = 'O';
        break;
        case 'C':
        status = 'P';
        break;
        case 'P':
        status = 'PxC';
        break;
        case 'PxC':
        status = 'PxP';
        break;
      default:
        status = 'P';
        break;
    }
    this.apiservice.postTransactionChange(txt.id, status).subscribe(() => {
    }, (error) => {})
    txt.status = status;
  }

  changeStatusTransactionMaster(id) {
    let status = '';
    switch (this.status) {
      case 'PxP':
        status = 'C';
        break;
      case 'C':
        status = 'O';
        break;
        case 'C':
        status = 'P';
        break;
        case 'P':
        status = 'PxC';
        break;
        case 'PxC':
        status = 'PxP';
        break;
      default:
        status = 'P';
        break;
    }
    this.apiservice.postTransactionChange(id, status).subscribe(() => {
    }, (error) => {})
    this.status = status;
  }
}
