import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';

@Component({
  selector: 'app-acc-balance',
  templateUrl: './acc-balance.component.html',
  styleUrls: ['./acc-balance.component.scss']
})
export class AccBalanceComponent implements OnInit {

  public obj_list: object = null;

  public total_in: number = 0;
  public total_out: number = 0;
  public total: number = 0;

  constructor(private apiservice: ApiServices,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.apiservice.AccountsBalanceAll().subscribe((response) => {
      this.obj_list = response.results;
      this.calculateTotales(response.results);
    }, (error) => {
      this.toast.error('Cant obtain acc general balance', 'Uopsss...')
      console.error(error)
    });
  }

  calculateTotales(array_objects:any) {
    array_objects.forEach(acc => {
      this.total += this.getBalance(acc.total_in, acc.total_out)
    });
  }

  public getBalance(inTotal: number, outTotal: number) {
    return inTotal - outTotal
  }

}
