import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiServices } from 'src/services/services';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {

  @ViewChild('detailModal') detailModal: any;

  constructor(
    private apiservice: ApiServices,
    private modal: NgbModal,
    private toast: ToastrService) { }

  public obj: any = null;
  public clients: any = null;
  public search: any = null;
  public count: number = 0;
  public next: any = null;
  public previus: any = null;

  public detail_name = null;
  public detail_number = null;
  public detail_wallet = null;
  public detail_type = null;
  public detail_status = null;
  public detail_client = null;
  public detail_symbol = null;
  public detail_pk = null;
  public detail_swap_report = null;

  public name = null;
  public number = null;
  public wallet = null;
  public type = null;
  public status = true;
  public client = null;
  public symbol = null;
  public swap_report = null;

  ngOnInit(): void {
    this.getAccounst();
    this.getClientsAvailables();
  }

  getClientsAvailables() {
    this.apiservice.ClientAllAvailables().subscribe((data) => {
      this.clients = data.results;
    })
  }

  getAccounst() {
    if (localStorage.getItem('role') == 'ad') {
      this.apiservice.AccountsAll().subscribe((data) => {
        this.obj = data.results;
      }, (error) => {
        this.toast.error('Unable to get accounts', 'Ups...', { positionClass: 'toast-top-center' });
      });
    } else {
      this.apiservice.AccountsAvailables().subscribe((data) => {
        this.obj = data.results;
      }, (error) => {
        this.toast.error('Unable to get accounts', 'Ups...', { positionClass: 'toast-top-center' });
      });
    }
  }

  accountCreate() {
    console.log(this.type);
    this.apiservice.AccountsCreate(
      this.name, this.number, this.wallet, this.type,
      this.status, this.client, this.symbol, this.swap_report
    ).subscribe((result) => {
      this.modal.dismissAll();
      this.toast.success('Account Created', 'Success', { positionClass: 'toast-top-center' });
      this.ngOnInit();
    }, (error) => {
      console.error(error);
      this.toast.error('Unable to create account', 'Ups...', { positionClass: 'toast-top-center' });
    })
  }

  public onChnageType(value) {
    console.log(value);
    this.type = value;
  }

}
