import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable, pipe, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class ApiServices {

    constructor(private http: HttpClient,) { }
    //#region User
    public AuthtenticationUser(user: string, password: string): Observable<any> {
        const body = {
            'username': user,
            'password': password
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api-token-auth';
        console.log(uri);
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public LogOut(): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        const uri = environment.apiUrl + 'api/user/logout';

        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            localStorage.removeItem('token');
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getUserNotification(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/user/notifications';
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getUserProfile(id: string = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = id != null ? environment.apiUrl + 'api/user/update/' + id : environment.apiUrl + 'api/user/update';
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public UpdateUserProfile(Username, firstname, lastname, email, id: string = null): Observable<any> {
        const body = {
            "username": Username,
            "first_name": firstname,
            "last_name": lastname,
            "email": email,
        }
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = id != null ? environment.apiUrl + 'api/user/update/' + id : environment.apiUrl + 'api/user/update';
        return this.http.put(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getAllUser(search: string = ''): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = search == null || search == '' ? environment.apiUrl + 'api/user/list' : environment.apiUrl + 'api/user/list/' + search;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getUSerRole(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/user/role';
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public toogleUserStatus(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/user/status/' + id;
        return this.http.post(
            uri, '', httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public toogleUserAdmin(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/user/admin/' + id;
        return this.http.post(
            uri, '', httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public changeUserRole(id: string, role: string): Observable<any> {
        const body = {
            'role': role,
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/user/role/' + id;
        return this.http.put(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public CreateUserRole(user: string, password: string, email: string): Observable<any> {
        const body = {
            "username": user,
            "password": password,
            "email": email
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/user/create'
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }
    //#endregion

    //#region client
    public CreateClient(first_name: string,
        last_name: string,
        doc_number: string,
        email: string,
        internal:boolean=null): Observable<any> {
        const body = {
            'first_name': first_name,
            'last_name': last_name,
            'doc_number': doc_number,
            'email': email,
            'internal': internal? internal: null

        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/list/all'
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public ClientAll(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/list/all'
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public ClientAllAvailables(url_list: string = ''): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let uri = environment.apiUrl + 'api/client/list'
        uri = url_list != '' ? environment.apiUrl + 'api/clients/list/' + url_list : uri;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public ClientAllAvailablesSearch(serach: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/list/' + serach
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public clientDetail(pk: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/detail/' + pk
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public clientUpdate(first_name: string,
        last_name: string, doc_number: string,
        email: string, pk: string
    ): Observable<any> {
        const body = {
            'first_name': first_name,
            'last_name': last_name,
            'doc_number': doc_number,
            'email': email,
        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/detail/' + pk;
        return this.http.put(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public clientDelete(pk: string): Observable<any> {
        const body = {

        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/delete/' + pk;
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }
    //#endregion

    //#region Accounts
    public AccountsCreate(
        name: string,
        number: string,
        wallet: string,
        type: string,
        status: boolean,
        client: string,
        symbol: string,
        swap:boolean = false
    ): Observable<any> {
        const body = {
            "name": name,
            "number": number,
            "wallet": wallet,
            "type": type,
            "status": status,
            "client": client,
            "symbol": symbol,
            'swap_report': swap
        };
        console.log('body create acc',body);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/list/all'
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => {
                console.error(er);
             return throwError(er); })
        );
    }

    public AccountsAvailables(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/list'
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public AccountsAll(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/list/all'
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public AccountsAvailablesSearch(serach: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/list/' + serach
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public AccountsDetail(pk: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/detail/' + pk
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public AccountsUpdate(
        name: string, number: string, wallet: string, type: string,
        status: boolean, client: string, symbol: string, pk: string, swap_report: boolean = false,
    ): Observable<any> {
        const body = {
            'name': name,
            'number': number,
            'wallet': wallet,
            'type': type,
            'status': status,
            'client': client,
            'symbol': symbol,
            swap_report
        };
        console.log(body);
        console.log(pk);
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/detail/' + pk;
        return this.http.put(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public AccountsDelete(pk: string): Observable<any> {
        const body = {

        };
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/client/delete/' + pk;
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public AccountsBalanceAll(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/account/balance/all'
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    //#endregion

    //#region transsactions

    public getTransactions(url_list:string = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = url_list == null ? environment.apiUrl + 'api/transaction/list': url_list;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsById(filter_value: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/list/' + filter_value;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getFilterTransactions(filter_value: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/list/filter/' + filter_value;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getAccountTransactions(filter_value: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/list/account/' + filter_value;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public CreateTransactions(
        acc_from: string, acc_target: string, amount_initial: string, operator: string,
        amount_final: string, note: string, allowSubTrans:boolean, money_type:string, sub_id:string = '', type:string = '', client = ""
    ): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {
            "acc_from": acc_from,
            "acc_target": acc_target,
            "amount_initial": amount_initial,
            "operator": operator? operator: null,
            "amount_final": amount_final,
            "money_type": money_type,
            "note": note,
            "allowSubTrans": allowSubTrans,
            "master_trans": sub_id? sub_id: null,
            "type": type? type: null,
            "client": client? client: null,
        }
        console.log("++++++",body);
        const uri = environment.apiUrl + 'api/transaction/list';
        return this.http.post(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsAvailable(url_list:string = null, search:string = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let uri = url_list == null ? environment.apiUrl + 'api/transaction/list/available': url_list;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsSearch(search:string = null): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/list/filter/'+ search;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsFilterDate(date1: string, date2:string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/list/filter/date/'+ date1 + "/" + date2;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsFilterType(type:string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/list/filter/type/'+ type
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsDetail(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/detail/' + id;
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public TransactionsUpdate(id: string, acc_from: string, acc_target: string, amount_initial: string, operator: string,
        amount_final: string, note: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {
            'acc_from' : acc_from,
            'acc_target' : acc_target,
            'amount_initial' : amount_initial,
            'operator' : operator,
            'amount_final' : amount_final,
            'note' : note
        }
        const uri = environment.apiUrl + 'api/transaction/detail/' + id;
        return this.http.put(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsBalance(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/record';
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsTypeBalance(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/record/status';
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public getTransactionsTypes(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const uri = environment.apiUrl + 'api/transaction/types/list';
        return this.http.get(
            uri, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }

    public postTransactionChange(id: string, status:string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body =  {
            'status': status,
        }
        const uri = environment.apiUrl + 'api/transaction/change/status/' + id;
        return this.http.put(
            uri, body, httpOptions
        ).pipe(map((response: any) => {
            return response;
        }),
            catchError((er) => { return throwError(er); })
        );
    }


    //#endregion

}
